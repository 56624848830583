var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"editor-container"},[(_vm.context === 'BWS.ARTICLE')?_c('link',{attrs:{"rel":"stylesheet","type":"text/css","href":_vm.stylesheets[_vm.context]}}):_vm._e(),(_vm.context === 'BUDS.EMAIL')?_c('link',{attrs:{"rel":"stylesheet","type":"text/css","href":_vm.stylesheets[_vm.context]}}):_vm._e(),(_vm.context === 'BEM.EVENT')?_c('link',{attrs:{"rel":"stylesheet","type":"text/css","href":_vm.stylesheets[_vm.context]}}):_vm._e(),(_vm.context === 'BDB.BADGE')?_c('link',{attrs:{"rel":"stylesheet","type":"text/css","href":_vm.stylesheets[_vm.context]}}):_vm._e(),_c('div',{staticClass:"editor-toolbar",class:{
      'is-side': _vm.isSideBySide,
      'is-readmode': _vm.isReadmode
    }},[_vm._l((_vm.toolbar),function(item,index){return [(item.type === 'button')?_c('a',{key:index,class:item.className,attrs:{"title":item.tooltips},on:{"click":function($event){return _vm.executeAction(item.action || '')}}}):_c('i',{key:index,staticClass:"separator"},[_vm._v("|")])]})],2),_c('div',{staticClass:"editor-bottombar"},[_c('div',{staticClass:"status-bar"},[_c('img',{staticClass:"logo",attrs:{"src":"static/img/logo.jpeg","alt":""}}),_c('span',{staticClass:"lines"},[_vm._v("lines: "+_vm._s(_vm.lines))]),_c('span',{staticClass:"words"},[_vm._v("words: "+_vm._s(_vm.words))])])]),_c('div',{ref:"editor",staticClass:"editor is-side"}),_c('div',{ref:"preview",staticClass:"editor-preview",class:{
      'is-side': _vm.isSideBySide,
      'is-readmode': _vm.isReadmode,
      'article-content': _vm.context === 'BWS.ARTICLE',
      'email-content': _vm.context === 'BUDS.EMAIL',
      'event-content': _vm.context === 'BEM.EVENT',
      'badge-content': _vm.context === 'BDB.BADGE'
    }}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showToc),expression:"showToc"}],staticClass:"toc-preview"},[(_vm.tableOfContent.length > 0)?_c('toc',{attrs:{"list":_vm.tableOfContent}}):_vm._e()],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showHtmlCode),expression:"showHtmlCode"}],staticClass:"html-code-preview"},[_c('textarea',{attrs:{"readonly":""},domProps:{"textContent":_vm._s(_vm.htmlCode)}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }